import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import { createAnswer } from "../features/answer";

const StorePage: React.FC = () => {
  const navigate = useNavigate();
  const [storeName, setStoreName] = useState("");
  const [alertOpened, setAlertOpened] = useState(false);
  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!storeName?.trim()) {
      setAlertOpened(true);
    } else {
      const answer = await createAnswer(storeName);
      navigate("/survey/0", {
        state: answer,
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          minHeight: "var(--screen-height)",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: "4rem 1rem",
          justifyContent: "space-evenly",
          maxWidth: "30rem",
          margin: "0 auto",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="h2">매장 이름이 무엇이신가요?</Typography>
          <Typography variant="caption">
            *맞춤형 대화에서 불러드릴 매장명입니다
          </Typography>
        </Box>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
          onSubmit={handleSubmit}
        >
          <TextField
            variant="outlined"
            color="primary"
            placeholder="매장명을 입력해 주세요 |"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ fontWeight: "bold" }}
          >
            시작하기
          </Button>
        </Box>
      </Box>
      <AlertDialog
        message={"매장명을 입력해 주세요."}
        open={!!alertOpened}
        onClose={() => setAlertOpened(false)}
      />
    </>
  );
};

export default StorePage;
