import { Answer } from "./answer";
import { Message } from "./message";
import { CustomerType, interiorStyleNames } from "./question";

const customerMents: Record<CustomerType, string> = {
  [CustomerType.WINE_LOVER]: "와인 애호가들이 많이오는",
  [CustomerType.PRESENT]: "30 - 40 대 손님이 많은",
  [CustomerType.EVENT]: "찾아서오는 손님 많은",
  [CustomerType.REGULAR]: "단골 분들이 많은",
  [CustomerType.EPICUREAN]: "미식가들이 즐겨찾는",
  [CustomerType.REGULAR_2]: "단골 분들이 많은",
  [CustomerType.LOVERS]: "데이트하러 오는 연인이 많은",
  [CustomerType.OFFICE_WORKERS]: "30 - 40 대 손님이 많은",
  [CustomerType.TOURISTS]: "찾아서오는 손님 많은",
};

export function createStoreAnalysisMessages(
  answer: Partial<Answer>
): Message[] {
  if (
    answer.storeName == null ||
    answer.interior == null ||
    answer.customer == null
  ) {
    throw new Error("잘못된 접근입니다.");
  }

  return [
    { message: `${answer.storeName}님의 매장은` },
    { message: customerMents[answer.customer] },
    { message: `${interiorStyleNames[answer.interior]} 매장이군요!` },
    { message: "네, 맞아요!", mine: true },
    { message: "그럼 몇가지만 더 여쭤볼게요!" },
  ];
}
