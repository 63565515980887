import { Answer } from "./answer";
import { Message } from "./message";
import {
  foodTypeNames,
  meatTypeNames,
  MusicType,
  PriceType,
  StoreType,
  storeTypeNames,
  WineStyle,
} from "./question";

const musicMents: Record<MusicType, string> = {
  [MusicType.CLASSIC]: "고급스러운 분위기의",
  [MusicType.JAZZ]: "여유있는 분위기의",
  [MusicType.POP]: "활기찬 분위기의",
  [MusicType.ACOUSTIC]: "아늑한 분위기의",
  [MusicType.KPOP]: "트렌디한 분위기의",
};

const priceMents: Record<PriceType, string> = {
  [PriceType.LOW]: "1-2 만원대의 가성비 좋고",
  [PriceType.MEDIUM]: "3-5 만원대의",
  [PriceType.HIGH]: "6-9 만원대의",
  [PriceType.PREMIUM]: "10 만원 이상의",
  [PriceType.ALL]: "모든 가격대 다양하게",
};

const wineStyleMents: Record<WineStyle, string> = {
  [WineStyle.POPULAR]: "인기있는 와인",
  [WineStyle.RARE]: "희귀한, 특별한 와인",
  [WineStyle.BEST]: "퀄리티가 좋은 와인",
};

function formatStoreType(answer: Partial<Answer>) {
  if (answer.storeType == null) throw new Error("잘못된 접근입니다.");

  switch (answer.storeType) {
    case StoreType.ETC:
      // TODO: test
      return null;
    case StoreType.FINE_DINING:
    case StoreType.RESTAURANT:
      if (answer.food == null) throw new Error("잘못된 접근입니다.");
      return `${foodTypeNames[answer.food]} ${
        storeTypeNames[answer.storeType]
      }인`;
    case StoreType.MEAT_RESTAURANT:
      if (answer.meat == null) throw new Error("잘못된 접근입니다.");
      return `${meatTypeNames[answer.meat]} 전문점인`;
    default:
      return `${storeTypeNames[answer.storeType]} 인`;
  }
}

export function createWineAnalysisMessages(answer: Partial<Answer>): Message[] {
  if (answer.price == null || answer.wineStyle == null)
    throw new Error("잘못된 접근입니다.");

  const storeTypeText = formatStoreType(answer);
  const priceText = priceMents[answer.price];
  const wineStyleText = wineStyleMents[answer.wineStyle];

  return [
    { message: musicMents[answer.music!], mine: false },
    storeTypeText ? { message: storeTypeText, mine: false } : null,
    { message: `${answer.storeName} 님의 매장과 어울리는` },
    { message: `${priceText} ${wineStyleText}을 찾아드릴게요` },
    { message: "네 ! 기대할게요 !", mine: true },
  ].filter((x): x is Message => !!x);
}
