import { useLayoutEffect } from "react";

export function useScreenHeightCssVariable() {
  const measure = () => {
    const root = document.querySelector(":root") as HTMLElement;
    root.style.setProperty("--screen-height", window.innerHeight + "px");
  };
  useLayoutEffect(() => {
    measure();
    window.addEventListener("resize", measure);
    window.addEventListener("orientationchange", measure);
    return () => {
      window.removeEventListener("resize", measure);
      window.removeEventListener("orientationchange", measure);
    };
  }, []);
}
