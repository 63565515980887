import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import { getAnswer } from "./features/answer";
import { getResultWines } from "./features/wine";
import HomePage from "./pages";
import ResultPage from "./pages/result";
import StorePage from "./pages/store";
import SurveyPage from "./pages/survey";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "store",
        element: <StorePage />,
      },
      {
        path: "survey/:step",
        element: <SurveyPage />,
      },
      {
        path: "result/:id",
        element: <ResultPage />,
        loader: async ({ params }) => {
          const answer = await getAnswer(params.id!);
          const wines = await getResultWines(answer);
          return { answer, wines };
        },
      },
      {
        path: "*",
        element: <Navigate replace to="/" />,
      },
    ],
  },
]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
