import { createTheme } from "@mui/material/styles";
import { darken, opacify } from "polished";

export const colors = {
  wine: "#901054",
  silver: "#E3E3E3",
};

export const theme = createTheme({
  palette: {
    divider: "rgba(0, 0, 0, 0.09)",
    primary: {
      main: colors.wine,
      dark: darken(0.05, colors.wine),
      contrastText: "#fff",
    },
    secondary: {
      main: colors.silver,
      dark: darken(0.05, colors.silver),
    },
  },
  typography: {
    fontFamily: ["Pretendard Variable", "sans-serif"].join(", "),
    caption: { fontSize: "0.8rem", fontWeight: "300" },
    h1: { fontSize: "2.2rem", fontWeight: "700" },
    h2: { fontSize: "2rem", fontWeight: "700" },
    h3: { fontSize: "1.8rem", fontWeight: "700" },
    h4: { fontSize: "1.6rem", fontWeight: "700" },
    h5: { fontSize: "1.38rem", fontWeight: "700" },
    h6: { fontSize: "1.2rem", fontWeight: "700" },
  },
  components: {
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "0.85em",
          padding: "0.85em 2em",
          fontWeight: 300,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: "0.85em",
          color: theme.palette[ownerState.color!].main,
          ":hover fieldset": {
            borderColor: `${theme.palette[ownerState.color!].main} !important`,
          },
        }),

        input: ({ ownerState, theme }) => ({
          padding: "0.85em",
          textAlign: "center",
          color: theme.palette[ownerState.color!].main,
        }),
        notchedOutline: ({ ownerState, theme }) => ({
          borderColor: opacify(-0.25, theme.palette[ownerState.color!].main),
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "1rem",
        },
      },
    },
  },
});
