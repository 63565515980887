import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";

export type PendingOverlayProps = {};

const PendingOverlay: React.FC<PendingOverlayProps> = () => {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "var(--screen-height)",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PendingOverlay;
