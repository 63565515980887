export type Option<T> = {
  title: string;
  value: T;
};

export type Question<T> = {
  type: string;
  question: string;
  options: Option<T>[];
};

export enum StoreType {
  BOTTLE_SHOP = "BOTTLE_SHOP",
  BAR = "BAR",
  FINE_DINING = "FINE_DINING",
  RESTAURANT = "RESTAURANT",
  MEAT_RESTAURANT = "MEAT_RESTAURANT",
  CAFE = "CAFE",
  PUB = "PUB",
  HOTEL = "HOTEL",
  ETC = "ETC",
}

export const storeTypeNames = {
  [StoreType.BOTTLE_SHOP]: "보틀샵",
  [StoreType.BAR]: "와인바 / 바",
  [StoreType.FINE_DINING]: "파인다이닝",
  [StoreType.RESTAURANT]: "레스토랑",
  [StoreType.MEAT_RESTAURANT]: "육류 (스테이크, 샤퀴테리) 전문점",
  [StoreType.CAFE]: "카페",
  [StoreType.PUB]: "호프 & 펍",
  [StoreType.HOTEL]: "호텔 & 리조트",
  [StoreType.ETC]: "기타 매장",
};

export const storeTypeQuestion: Question<StoreType> = {
  type: "storeType",
  question: "어떤 매장이신가요?",
  options: Object.values(StoreType).map((value) => ({
    title: storeTypeNames[value],
    value,
  })),
};

export enum FoodType {
  KOREAN = "KOREAN",
  JAPANESE = "JAPANESE",
  CHINESE = "CHINESE",
  FRENCH = "FRENCH",
  ITALIAN = "ITALIAN",
  SPANISH = "SPANISH",
  VEGAN = "VEGAN",
  NORTHERN_EUROPE = "NORTHERN_EUROPE",
  ASIAN = "ASIAN",
  FUSION = "FUSION",
  ETC_WESTERN = "ETC",
}

export const foodTypeNames = {
  [FoodType.KOREAN]: "한식",
  [FoodType.JAPANESE]: "일식",
  [FoodType.CHINESE]: "중식",
  [FoodType.FRENCH]: "프렌치",
  [FoodType.ITALIAN]: "이탈리아",
  [FoodType.SPANISH]: "스페니쉬",
  [FoodType.VEGAN]: "비건",
  [FoodType.NORTHERN_EUROPE]: "북유럽",
  [FoodType.ASIAN]: "아시아 (동남아, 인도 등)",
  [FoodType.FUSION]: "퓨전",
  [FoodType.ETC_WESTERN]: "기타양식",
};

export const foodQuestion: Question<FoodType> = {
  type: "food",
  question: "어떤 요리를 주로 하시나요?",
  options: Object.values(FoodType).map((value) => ({
    title: foodTypeNames[value],
    value,
  })),
};

export enum MeatType {
  CHARCUTERIE = "CHARCUTERIE",
  PORK = "PORK",
  BEEF = "BEEF",
  LAMB = "LAMB",
  BIRD = "BIRD",
}

export const meatTypeNames = {
  [MeatType.CHARCUTERIE]: "샤퀴테리",
  [MeatType.PORK]: "돼지고기",
  [MeatType.BEEF]: "소고기",
  [MeatType.LAMB]: "양고기",
  [MeatType.BIRD]: "조류",
};

export const meatQuestion: Question<MeatType> = {
  type: "meat",
  question: "어떤 육류를 주로 하시나요?",
  options: Object.values(MeatType).map((value) => ({
    title: meatTypeNames[value],
    value,
  })),
};

export enum InteriorStyle {
  MODERN = "MODERN",
  WARM = "WARM",
  SIMPLE = "SIMPLE",
  HIP = "HIP",
  CLASSIC = "CLASSIC",
}

export const interiorStyleNames = {
  [InteriorStyle.MODERN]: "모던하고 세련된",
  [InteriorStyle.WARM]: "따뜻하고 아늑한",
  [InteriorStyle.SIMPLE]: "심플하고 깔끔한",
  [InteriorStyle.HIP]: "힙하고 트렌디한",
  [InteriorStyle.CLASSIC]: "클래식하고 우아한",
};

export const interiorQuestion: Question<InteriorStyle> = {
  type: "interior",
  question: "매장 인테리어는 어떤 분위기인가요?",
  options: Object.values(InteriorStyle).map((value) => ({
    title: interiorStyleNames[value],
    value,
  })),
};

export enum CustomerType {
  WINE_LOVER = "WINE_LOVER",
  PRESENT = "PRESENT",
  EVENT = "EVENT",
  REGULAR = "REGULAR",
  EPICUREAN = "EPICUREAN",
  REGULAR_2 = "REGULAR_2",
  LOVERS = "LOVERS",
  OFFICE_WORKERS = "OFFICE_WORKERS",
  TOURISTS = "TOURISTS",
}

export const customerTypeNames = {
  [CustomerType.WINE_LOVER]: "취향이 확실한 와인 애호가",
  [CustomerType.PRESENT]: "선물용 와인을 사러오는 주변 직장인",
  [CustomerType.EVENT]: "파티, 캠핑, 피크닉 등 이벤트날 오시는 손님들",
  [CustomerType.REGULAR]: "혼술이나 소규모 홈파티를 즐기는 동네 단골",
  [CustomerType.EPICUREAN]: "검색해서 찾아오는 미식가들",
  [CustomerType.REGULAR_2]: "항상 찾아주시는 단골손님들",
  [CustomerType.LOVERS]: "데이트하러 오는 연인들",
  [CustomerType.OFFICE_WORKERS]: "매장 주변 직장인들",
  [CustomerType.TOURISTS]: "여행 온 관광객들",
};

export const bottleShopCustomerQuestion: Question<CustomerType> = {
  type: "customer",
  question: "주로 오시는 손님은 어떤 스타일인가요?",
  options: [
    CustomerType.WINE_LOVER,
    CustomerType.PRESENT,
    CustomerType.EVENT,
    CustomerType.REGULAR,
  ].map((value) => ({ title: customerTypeNames[value], value })),
};

export const customerQuestion: Question<CustomerType> = {
  type: "customer",
  question: "주로 오시는 손님은 어떤 스타일인가요?",
  options: [
    CustomerType.EPICUREAN,
    CustomerType.REGULAR_2,
    CustomerType.LOVERS,
    CustomerType.OFFICE_WORKERS,
    CustomerType.TOURISTS,
  ].map((value) => ({
    title: customerTypeNames[value],
    value,
  })),
};

export enum MusicType {
  CLASSIC = "CLASSIC",
  JAZZ = "JAZZ",
  POP = "POP",
  ACOUSTIC = "ACOUSTIC",
  KPOP = "KPOP",
}

export const musicTypeNames = {
  [MusicType.CLASSIC]: "고풍스러운 클래식",
  [MusicType.JAZZ]: "여유있고 분위기 있는 재즈",
  [MusicType.POP]: "신나고 리드미컬한 팝",
  [MusicType.ACOUSTIC]: "잔잔하고 아늑한 인디, 어쿠스틱",
  [MusicType.KPOP]: "트렌디한 K-POP",
};

export const musicQuestion: Question<MusicType> = {
  type: "music",
  question: "주로 트는 플레이 리스트는 어떤 타입인가요?",
  options: Object.values(MusicType).map((value) => ({
    title: musicTypeNames[value],
    value,
  })),
};

export enum ReferrerType {
  SNS = "SNS",
  RECOMMENDATION = "RECOMMENDATION",
  COMMUNITY = "COMMUNITY",
  NEWS = "NEWS",
  ETC = "ETC",
}

export const referrerTypeNames = {
  [ReferrerType.SNS]: "SNS 광고",
  [ReferrerType.RECOMMENDATION]: "주변 지인 추천",
  [ReferrerType.COMMUNITY]: "카페 또는 커뮤니티",
  [ReferrerType.NEWS]: "뉴스",
  [ReferrerType.ETC]: "기타",
};

export const referrerQuestion: Question<ReferrerType> = {
  type: "referrer",
  question: "어떤 경로로 '우리매장와인찾기'를 하게 되셨나요?",
  options: Object.values(ReferrerType).map((value) => ({
    title: referrerTypeNames[value],
    value,
  })),
};

export enum WineStyle {
  POPULAR = "POPULAR",
  RARE = "RARE",
  BEST = "BEST",
}

export const wineStyleNames = {
  [WineStyle.POPULAR]: "모두에게 사랑받는 인지도 있는 와인",
  [WineStyle.RARE]: "아는 사람이 많이 없는 희귀하고 특별한 와인",
  [WineStyle.BEST]: "퀄리티가 높고 전문가 평이 좋은 와인",
};

export const wineStyleQuestion: Question<WineStyle> = {
  type: "wineStyle",
  question: "어떤 와인을 찾고 계신가요?",
  options: Object.values(WineStyle).map((value) => ({
    title: wineStyleNames[value],
    value,
  })),
};

export enum PriceType {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  PREMIUM = "PREMIUM",
  ALL = "ALL",
}

export const priceTypeNames = {
  [PriceType.LOW]: "1-2 만원 대 / 가성비",
  [PriceType.MEDIUM]: "3-5 만원 대 / 합리적인",
  [PriceType.HIGH]: "6-9 만원 대 / 고퀄리티",
  [PriceType.PREMIUM]: "10만원 이상 / 프리미엄",
  [PriceType.ALL]: "모든 가격대 다양하게",
};

export const priceTypeRanges = {
  [PriceType.LOW]: [1, 2],
  [PriceType.MEDIUM]: [3, 5],
  [PriceType.HIGH]: [6, 9],
  [PriceType.PREMIUM]: [10, Infinity],
  [PriceType.ALL]: [-Infinity, Infinity],
};

export const priceQuestion: Question<PriceType> = {
  type: "price",
  question: "구매하시는 와인 가격대는 어느 정도 생각하고 계신가요?",
  options: Object.values(PriceType).map((value) => ({
    title: priceTypeNames[value],
    value,
  })),
};
