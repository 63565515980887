import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AnswerList from "../components/AnswerList";
import Chat from "../components/Chat";
import Dot from "../components/Dot";
import { Answer, saveAnswer } from "../features/answer";
import { createSteps, QuestionStep, Step } from "../features/step";
import { createStoreAnalysisMessages } from "../features/storeAnalysis";
import { createWineAnalysisMessages } from "../features/wineAnalysis";
import { delay } from "../helpers/functions";
import cheers from "../images/cheers.svg";

export type SurveyPageProps = {};

const SurveyPage: React.FC<SurveyPageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ step: string }>();
  const index = parseInt(params.step!, 10);
  const answer: Partial<Answer> = location.state ?? {};
  const steps = createSteps(answer);
  const step = steps[index];

  const onComplete = async (answer: Partial<Answer>) => {
    await saveAnswer(answer);
    navigate({
      pathname: "/result/" + answer.id,
    });
  };

  const onAnswer = async (value: string) => {
    const nextIndex = index + 1;
    if (step.type !== "QUESTION") return;
    const next = steps[nextIndex];
    const newAnswer = {
      ...answer,
      [step.question.type]: value,
    };
    if (next == null) {
      onComplete(newAnswer);
    } else {
      setTimeout(() => {
        navigate("/survey/" + nextIndex, {
          state: newAnswer,
        });
      }, 300);
    }
  };

  const onAnalyzed = () => {
    const next = steps[index + 1];
    if (next == null) {
      onComplete(answer);
    } else {
      navigate("/survey/" + (index + 1), {
        state: answer,
      });
    }
  };

  if (step == null || answer.id == null || answer.storeName == null) {
    return <Navigate replace to="/store" />;
  }

  return step.type === "QUESTION" ? (
    <QuestionView steps={steps} step={step} index={index} onAnswer={onAnswer} />
  ) : step.type === "STORE_ANALYZE" ? (
    <StoreAnalyzeView answer={answer} onCompleted={onAnalyzed} />
  ) : (
    <WineAnalyzeView answer={answer} onCompleted={onAnalyzed} />
  );
};

export default SurveyPage;

export type QuestionViewProps = {
  steps: Step[];
  step: QuestionStep;
  index: number;
  onAnswer?: (answer: string) => void;
};

const QuestionView: React.FC<QuestionViewProps> = ({
  steps,
  step,
  index,
  onAnswer,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "var(--screen-height)",
        justifyContent: "center",
        padding: {
          xs: "6vh 1rem",
          sm: "10vh 1rem",
        },
        gap: "5vh",
        maxWidth: "30rem",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            xs: "8vh",
            sm: "12vh",
          },
          marginBottom: "auto",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.75rem",
          }}
        >
          {steps.map((step, idx) =>
            step.type === "QUESTION" ? (
              <Dot key={step.question.type} active={idx < index} />
            ) : null
          )}
        </Box>
        <motion.div
          key={step.question.type}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0, transition: { delay: 0.1 } }}
        >
          <Typography variant="h3" sx={{ wordBreak: "keep-all" }}>
            {step.question.question}
          </Typography>
        </motion.div>
      </Box>
      <AnswerList
        key={step.question.type}
        options={step.question.options}
        onSelect={onAnswer}
      />
    </Box>
  );
};

export type StoreAnalyzeViewProps = {
  answer: Partial<Answer>;
  onCompleted: () => void;
};

const StoreAnalyzeView: React.FC<StoreAnalyzeViewProps> = ({
  answer,
  onCompleted,
}) => {
  const [showChat, setShowChat] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const messages = createStoreAnalysisMessages(answer);

  return (
    <Box
      sx={{
        minHeight: "var(--screen-height)",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "6vh 1rem",
        gap: "6vh",
        maxWidth: "30rem",
        margin: "0 auto",
      }}
    >
      <Typography
        component={motion.div}
        variants={{
          hide: { scale: 0.75, opacity: 0 },
          show: { scale: 1, opacity: 1 },
        }}
        initial="hide"
        animate="show"
        variant="h5"
        fontWeight="600"
        onAnimationComplete={async () => {
          await delay(1000);
          setShowChat(true);
          console.log("CALLED");
        }}
      >
        {answer.storeName ?? "알수없음"} 님의 <br />
        매장 유형을 분석하고 있어요..
      </Typography>
      {showChat && (
        <Chat
          messages={messages}
          onCompleted={async () => {
            await delay(1000);
            setShowImage(true);
          }}
        />
      )}
      {showImage && (
        <Box
          component={motion.img}
          variants={{
            hide: { scale: 0.75, opacity: 0 },
            show: { scale: 1, opacity: 1 },
          }}
          initial="hide"
          animate="show"
          src={cheers}
          sx={{ width: "60%", maxWidth: "20rem", margin: "0 auto" }}
          onAnimationComplete={async () => {
            await delay(1000);
            onCompleted();
            console.log("CALLED");
          }}
        />
      )}
    </Box>
  );
};

export type WineAnalyzeViewProps = {
  answer: Partial<Answer>;
  onCompleted: () => void;
};

const WineAnalyzeView: React.FC<WineAnalyzeViewProps> = ({
  answer,
  onCompleted,
}) => {
  const messages = createWineAnalysisMessages(answer);
  const [showText, setShowText] = useState(false);
  const [showImage, setShowImage] = useState(false);

  return (
    <Box
      sx={{
        minHeight: "var(--screen-height)",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "7vh 1rem",
        gap: "7vh",
        maxWidth: "30rem",
        margin: "0 auto",
      }}
    >
      <Chat
        messages={messages}
        onCompleted={async () => {
          await delay(1000);
          setShowText(true);
        }}
      />
      {showText && (
        <Typography
          component={motion.div}
          variants={{
            hide: { scale: 0.75, opacity: 0 },
            show: { scale: 1, opacity: 1 },
          }}
          initial="hide"
          animate="show"
          variant="h5"
          fontWeight="600"
          textAlign="center"
          onAnimationComplete={async () => {
            await delay(2000);
            setShowImage(true);
          }}
        >
          매장에 어울리는 와인을 찾고 있어요...
        </Typography>
      )}
      {showImage && (
        <Box
          component={motion.img}
          variants={{
            hide: { scale: 0.75, opacity: 0 },
            show: { scale: 1, opacity: 1 },
          }}
          initial="hide"
          animate="show"
          src={cheers}
          sx={{ width: "60%", maxWidth: "20rem", margin: "0 auto" }}
          onAnimationComplete={async () => {
            await delay(1000);
            onCompleted();
          }}
        />
      )}
    </Box>
  );
};
