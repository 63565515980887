import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import hero from "../images/hero.svg";

const HomePage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: "var(--screen-height)",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "12vh 1rem",
        justifyContent: "space-between",
        gap: "2rem",
        maxWidth: "30rem",
        margin: "0 auto",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        <Typography variant="h1" fontWeight="800">
          우리 매장에
          <br />딱 맞는 와인 찾기
        </Typography>
        <Typography variant="caption">
          <span style={{ fontSize: "1.1em" }}>AI</span>가 매장의 분위기와 컨셉에
          맞춰 와인을 추천해 드려요.
        </Typography>
      </Box>
      <Box
        component="img"
        src={hero}
        alt="와인 이미지"
        sx={{ width: "100%" }}
      />
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/store"
        sx={{
          fontWeight: "bold",
        }}
      >
        우리매장 와인 찾아보기
      </Button>
    </Box>
  );
};

export default HomePage;
