import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AnimatePresence } from "framer-motion";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";
import PendingOverlay from "./components/PendingOverlay";
import { useScreenHeightCssVariable } from "./helpers/useScreenHeightCssVariable";
import { theme } from "./theme";

function App() {
  useScreenHeightCssVariable();
  const { state } = useNavigation();
  return (
    <ThemeProvider theme={theme}>
      <ScrollRestoration />
      <CssBaseline />
      <GlobalStyles
        styles={(theme) => ({
          "::selection": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        })}
      />
      <Outlet />
      <AnimatePresence>
        {state === "loading" && <PendingOverlay key="pending" />}
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default App;
