import Box from "@mui/material/Box";
import { motion } from "framer-motion";

export type MessageProps = {
  mine?: boolean;
  children: string;
};

const Message: React.FC<MessageProps> = ({ mine, children }) => {
  return (
    <Box
      component={motion.div}
      variants={{
        hide: { opacity: 0, x: mine ? 50 : -50 },
        show: { opacity: 1, x: 0 },
      }}
      sx={{
        padding: "0.45rem 0.9rem",
        borderRadius: "0.66rem",
        marginLeft: mine ? "auto" : 0,
        color: mine ? "secondary.contrastText" : "primary.contrastText",
        backgroundColor: mine ? "secondary.main" : "primary.main",
        fontSize: "1rem",
        fontWeight: "300",
        textAlign: mine ? "right" : "left",
        lineHeight: 1.2,
      }}
    >
      {children}
    </Box>
  );
};

export default Message;
