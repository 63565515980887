import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  CustomerType,
  FoodType,
  InteriorStyle,
  MeatType,
  MusicType,
  PriceType,
  ReferrerType,
  StoreType,
  WineStyle,
} from "./question";

export type Answer = {
  id: string;
  storeName: string;
  storeType: StoreType;
  food?: FoodType;
  meat?: MeatType;
  interior: InteriorStyle;
  customer: CustomerType;
  music: MusicType;
  referrer: ReferrerType;
  wineStyle: WineStyle;
  price: PriceType;
  createdAt: Date;
};

export async function createAnswer(
  storeName: string
): Promise<Partial<Answer>> {
  const ref = doc(collection(db, "answers"));
  return {
    id: ref.id,
    storeName,
  };
}

export async function saveAnswer(answer: Partial<Answer>) {
  const ref = doc(db, "answers", answer.id!);
  await setDoc(
    ref,
    { ...answer, createdAt: serverTimestamp() },
    { merge: false }
  );
}

export async function getAnswer(id: string): Promise<Answer> {
  const ref = doc(db, "answers", id);
  const snapshot = await getDoc(ref);
  const data = snapshot.data() as any;
  return { ...data, createdAt: data.createdAt?.toDate() };
}
