import { Answer } from "./answer";
import {
  bottleShopCustomerQuestion,
  customerQuestion,
  foodQuestion,
  interiorQuestion,
  meatQuestion,
  musicQuestion,
  priceQuestion,
  Question,
  referrerQuestion,
  StoreType,
  storeTypeQuestion,
  wineStyleQuestion,
} from "./question";

export type QuestionStep<T = unknown> = {
  type: "QUESTION";
  question: Question<T>;
};

export type StoreAnalyzeStep = {
  type: "STORE_ANALYZE";
};

export type WineAnalyzeStep = {
  type: "WINE_ANALYZE";
};

export type Step = QuestionStep | StoreAnalyzeStep | WineAnalyzeStep;

export function createSteps(answer: Partial<Answer>): Step[] {
  const steps: (Step | null)[] = [
    {
      type: "QUESTION",
      question: storeTypeQuestion,
    },
    answer.storeType != null &&
    [StoreType.FINE_DINING, StoreType.RESTAURANT].includes(answer.storeType)
      ? {
          type: "QUESTION",
          question: foodQuestion,
        }
      : answer.storeType === StoreType.MEAT_RESTAURANT
      ? {
          type: "QUESTION",
          question: meatQuestion,
        }
      : null,
    {
      type: "QUESTION",
      question: interiorQuestion,
    },
    {
      type: "QUESTION",
      question:
        answer.storeType === StoreType.BOTTLE_SHOP
          ? bottleShopCustomerQuestion
          : customerQuestion,
    },
    {
      type: "QUESTION",
      question: musicQuestion,
    },
    { type: "STORE_ANALYZE" },
    {
      type: "QUESTION",
      question: referrerQuestion,
    },
    {
      type: "QUESTION",
      question: wineStyleQuestion,
    },
    {
      type: "QUESTION",
      question: priceQuestion,
    },
    { type: "WINE_ANALYZE" },
  ];

  return steps.filter((step): step is Step => !!step);
}
