import axios from "axios";
import { Answer } from "./answer";
import { FoodType, MeatType, PriceType, StoreType } from "./question";

export enum WineType {
  RED = "RED",
  WHITE = "WHITE",
  SPARKLING = "SPARKLING",
  ROSE = "ROSE",
  NATURAL = "NATURAL",
}

export type Wine = {
  id: string | null;
  type: WineType;
  name: string;
  nameEnglish: string;
  description: string;
  tags: string[];
  provider: string;
  image: string;
};

export const exampleWines: Wine[] = [
  {
    id: null,
    type: WineType.RED,
    name: "도멘 뱅상 르디 뉘 생 조르주\n1er 레 포레 쌩 조르주 비에뉴 빈유",
    nameEnglish:
      "Vincent Ledy, Nuits St Georges\n1Er Cru Les Porets Saint Georges",
    description:
      "로파트 파커가 극찬한 브루고뉴 프리미에 크뤼 피노누아. 부드럽고 섬세하게 입안을 감싸는 붉은 베리,자두의 은은한 과실향과 부드럽게 숙성된 타닌, 진한오크향의 우아한 피니쉬가 환상적이다.",
    tags: ["로버트 파커 극찬", "브루고뉴 1er", "섬세함의 대명사"],
    provider: "코리아 와인즈",
    image: "/images/wine-example-red.png",
  },
  {
    id: null,
    type: WineType.WHITE,
    name: "윌리엄 셀럼’에스테이트’ 샤도네이",
    nameEnglish: "Williams Selyem, Unoaked Chardonnay",
    description:
      "로파트 파커가 극찬한 브루고뉴 프리미에 크뤼 피노누아. 부드럽고 섬세하게 입안을 감싸는 붉은 베리,자두의 은은한 과실향과 부드럽게 숙성된 타닌, 진한오크향의 우아한 피니쉬가 환상적이다.",
    tags: ["소노마 카운티의 야심작", "은은한 바닐라", "비비노 평점 4.6점"],
    provider: "코리아 와인즈",
    image: "/images/wine-example-white.png",
  },
  {
    id: null,
    type: WineType.SPARKLING,
    name: "샴페인 모드 셀렉션, 리저브",
    nameEnglish: "Champagne Mod Selection, Brut Reserve NV",
    description:
      "로파트 파커가 극찬한 브루고뉴 프리미에 크뤼 피노누아. 부드럽고 섬세하게 입안을 감싸는 붉은 베리,자두의 은은한 과실향과 부드럽게 숙성된 타닌, 진한오크향의 우아한 피니쉬가 환상적이다.",
    tags: [
      "리미티드 에디션",
      "깊고 우아한 풍미",
      "샴페인 애호가들의 Best of Best",
    ],
    provider: "코리아 와인즈",
    image: "/images/wine-example-sparkling.png",
  },
];

function getResultStoreType(answer: Answer) {
  switch (answer.meat) {
    case MeatType.BIRD:
      return 11;
    case MeatType.CHARCUTERIE:
      return 12;
    case MeatType.PORK:
      return 13;
    case MeatType.BEEF:
      return 14;
    case MeatType.LAMB:
      return 15;
  }

  switch (answer.food) {
    case FoodType.KOREAN:
      return 3;
    case FoodType.CHINESE:
      return 4;
    case FoodType.ASIAN:
      return 5;
    case FoodType.JAPANESE:
      return 6;
    case FoodType.FRENCH:
      return 7;
    case FoodType.ITALIAN:
      return 8;
    case FoodType.SPANISH:
      return 9;
    case FoodType.VEGAN:
      return 10;
    case FoodType.FUSION:
    case FoodType.ETC_WESTERN:
      return 11;
  }

  switch (answer.storeType) {
    case StoreType.BOTTLE_SHOP:
    case StoreType.HOTEL:
      return 1;
    case StoreType.BAR:
      return 2;
    case StoreType.CAFE:
    case StoreType.PUB:
    case StoreType.ETC:
      return 11;
  }

  console.log({
    answer,
  });
  throw new Error("알 수 없는 매장유형입니다.");
}

// https://stackoverflow.com/questions/68854198/did-google-sheets-stop-allowing-json-access
const SHEET_ID = process.env.REACT_APP_SHEET_ID;

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

type GoogleSheetsWine = {
  id: string;
  name: string;
  name_english: string;
  wine_type: string;
  tags: string;
  description: string;
  importer: string;
  image: string;
  price: string;
  style: string;
  store_type: string;
  music: string;
};

type GoogleSheetsResponseBody = {
  range: string;
  majorDimension: string;
  values: string[][];
};

async function getWines(): Promise<GoogleSheetsWine[]> {
  return axios
    .request<GoogleSheetsResponseBody>({
      url: `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/wines?alt=json&key=${GOOGLE_API_KEY}`,
    })
    .then((response) => {
      const indexes = {
        id: response.data.values[0].indexOf("id"),
        name: response.data.values[0].indexOf("name"),
        name_english: response.data.values[0].indexOf("name_english"),
        wine_type: response.data.values[0].indexOf("wine_type"),
        tags: response.data.values[0].indexOf("tags"),
        description: response.data.values[0].indexOf("description"),
        importer: response.data.values[0].indexOf("importer"),
        image: response.data.values[0].indexOf("image"),
        price: response.data.values[0].indexOf("price"),
        style: response.data.values[0].indexOf("style"),
        store_type: response.data.values[0].indexOf("store_type"),
        music: response.data.values[0].indexOf("music"),
      };
      return response.data.values.slice(1).map((row) => ({
        id: row[indexes.id],
        name: row[indexes.name],
        name_english: row[indexes.name_english],
        wine_type: row[indexes.wine_type],
        tags: row[indexes.tags],
        description: row[indexes.description],
        importer: row[indexes.importer],
        image: row[indexes.image],
        price: row[indexes.price],
        style: row[indexes.style],
        store_type: row[indexes.store_type],
        music: row[indexes.music],
      }));
    });
}

function matchPrice(answer: Answer, price: number) {
  if (answer.price === PriceType.ALL) {
    return true;
  } else if (answer.price === PriceType.LOW) {
    return price >= 10000 && price < 30000;
  } else if (answer.price === PriceType.MEDIUM) {
    return price >= 30000 && price < 60000;
  } else if (answer.price === PriceType.HIGH) {
    return price >= 60000 && price < 100000;
  } else if (answer.price === PriceType.PREMIUM) {
    return price > 100000;
  } else {
    return false;
  }
}

function matchWineStyle(answer: Answer, wineStyle: string) {
  return answer.wineStyle === wineStyle;
}

function matchStoreType(answer: Answer, storeType: number) {
  return getResultStoreType(answer) === storeType;
}

function matchWine(answer: Answer, wine: GoogleSheetsWine) {
  return (
    matchPrice(answer, parseInt(wine.price)) &&
    matchWineStyle(answer, wine.style) &&
    matchStoreType(answer, parseInt(wine.store_type))
  );
}

function sortWine(answer: Answer, wine: GoogleSheetsWine) {
  return answer.music.toString() === wine.music ? 1 : -1;
}

export async function getResultWines(answer: Answer) {
  const wines = await getWines();

  const matches = wines
    .filter((wine) => matchWine(answer, wine))
    .sort((_, wine) => sortWine(answer, wine));

  return [
    matches.find((x) => x.wine_type === "RED"),
    matches.find((x) => x.wine_type === "WHITE"),
    matches.find((x) => x.wine_type !== "RED" && x.wine_type !== "WHITE"),
  ]
    .filter((x): x is GoogleSheetsWine => x != null)
    .map((data) => ({
      id: !!data.id ? data.id : null,
      type: data.wine_type as WineType,
      name: data.name,
      nameEnglish: data.name_english,
      description: data.description,
      tags: data.tags.split(",").map((x) => x.trim()),
      provider: data.importer,
      image: data.image,
    }));
}
