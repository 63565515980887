import { Box, Typography } from "@mui/material";
import logo from "../images/logo.svg";

export type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F8F8F8",
        textAlign: "center",
        padding: "2.5rem 1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1.5rem",
      }}
    >
      <a href="https://www.marketbang.kr" target="_blank" rel="noreferrer">
        <Box component="img" src={logo} alt="Logo" sx={{ width: "7rem" }} />
      </a>
      <Typography variant="caption" color="text.secondary">
        Copyright(C) 2023. Three Labs. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
