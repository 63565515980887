import Box from "@mui/material/Box";
import { MouseEventHandler } from "react";

export type DotProps = {
  active?: boolean;
  onClick?: MouseEventHandler;
};

const Dot: React.FC<DotProps> = ({ active, onClick }) => {
  return (
    <Box
      component="button"
      onClick={onClick}
      sx={{
        width: "0.75rem",
        height: "0.75rem",
        padding: 0,
        border: 1,
        borderColor: "primary.main",
        borderRadius: "0.875rem",
        backgroundColor: active ? "primary.main" : "transparent",
      }}
    />
  );
};

export default Dot;
