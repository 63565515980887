import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { Option } from "../features/question";

export type AnswerListProps = {
  options: Option<any>[];
  selected?: string;
  onSelect?: (answer: string) => void;
};

const parentVariants: Variants = {
  initial: {
    x: 20,
  },
  show: {
    x: 0,
    transition: { staggerChildren: 0.1 },
  },
  exit: {
    x: -40,
    transition: {
      when: "afterChildren",
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
};

const childVariants: Variants = {
  initial: { x: 30, opacity: 0 },
  show: { x: 0, opacity: 1 },
  exit: { x: 30, opacity: 0 },
};

const AnswerList: React.FC<AnswerListProps> = ({
  options,
  selected: initialSelected,
  onSelect,
}) => {
  const [selected, setSelected] = useState<string | undefined>(initialSelected);
  const [expanded, setExpanded] = useState(false);
  const list = expanded ? options : options.slice(0, 6);
  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);
  const handleSelect = (value: string) => {
    onSelect?.(value);
    setSelected(value);
  };
  return (
    <Box
      component={motion.div}
      sx={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
      variants={parentVariants}
      initial="initial"
      animate="show"
      exit="exit"
      layoutRoot
    >
      {list.map((option) => (
        <motion.div
          key={option.value.toString()}
          variants={childVariants}
          layout
        >
          <Button
            fullWidth
            variant="contained"
            color={selected === option.value ? "primary" : "secondary"}
            onClick={() => handleSelect(option.value)}
          >
            {option.title}
          </Button>
        </motion.div>
      ))}
      {options.length > 5 && !expanded && (
        <motion.div key={"more"} variants={childVariants} layout>
          <Button
            fullWidth
            variant="text"
            size="small"
            color="inherit"
            onClick={() => setExpanded(true)}
          >
            더보기
          </Button>
        </motion.div>
      )}
    </Box>
  );
};

export default AnswerList;
