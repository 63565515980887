import {
  Box,
  Button,
  Card,
  Divider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { deepmerge } from "@mui/utils";
import { motion } from "framer-motion";
import { darken, opacify } from "polished";
import { ReactComponent as Sparkling } from "../images/sparkling.svg";
import { ReactComponent as Wave } from "../images/wave.svg";

const themes = {
  RED: {
    palette: {
      primary: {
        main: "#D73B8D",
        dark: darken(0.05, "#D73B8D"),
        contrastText: "#fff",
      },
    },
  },
  WHITE: {
    palette: {
      primary: {
        main: "#1BBDAA",
        dark: darken(0.05, "#1BBDAA"),
        contrastText: "#fff",
      },
    },
  },
  SPARKLING: {
    palette: {
      primary: {
        main: "#ABA169",
        dark: darken(0.05, "#ABA169"),
        contrastText: "#fff",
      },
    },
  },
  ROSE: {
    palette: {
      primary: {
        main: "#FF8574",
        dark: darken(0.05, "#FF8574"),
        contrastText: "#fff",
      },
    },
  },
  NATURAL: {
    palette: {
      primary: {
        main: "#A9AB36",
        dark: darken(0.05, "#A9AB36"),
        contrastText: "#fff",
      },
    },
  },
};

const decoColors = {
  RED: { background: "#A4135F", text: "white" },
  WHITE: { background: "#0F9F8E", text: "white" },
  SPARKLING: { background: "#FFF7CF", text: "#ABA169" },
  ROSE: { background: "#FFA79B", text: "white" },
  NATURAL: { background: "#DCDE68", text: "white" },
};

export type WineTileProps = {
  id: string | null;
  type: "RED" | "WHITE" | "SPARKLING" | "ROSE" | "NATURAL";
  name: string;
  nameEnglish: string;
  provider: string;
  description: string;
  tags: string[];
  image: string;
};

const WineTile: React.FC<WineTileProps> = ({
  id,
  type,
  name,
  nameEnglish,
  provider,
  description,
  tags,
  image,
}) => {
  const decoColor = decoColors[type];
  return (
    <ThemeProvider theme={(theme) => deepmerge(theme, themes[type])}>
      <Card
        component={motion.div}
        variants={{
          hide: { opacity: 0, scale: 0.5 },
          show: { opacity: 1, scale: 1 },
        }}
        sx={{ borderRadius: "0.75rem" }}
      >
        <Box sx={{ position: "relative" }}>
          {type === "NATURAL" ? (
            <Box
              sx={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "110%",
                aspectRatio: "1",
                color: decoColor.background,
              }}
            >
              <Wave width="100%" height="100%" />
            </Box>
          ) : type === "SPARKLING" ? (
            <Box
              sx={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "110%",
                aspectRatio: "1",
                color: decoColor.background,
              }}
            >
              <Sparkling width="100%" height="100%" />
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "120%",
                aspectRatio: "1",
                borderRadius: "100%",
                backgroundColor: decoColor.background,
              }}
            />
          )}
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              padding: "1rem",
              paddingBottom: 0,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: "bold", letterSpacing: 3, fontSize: "0.5rem" }}
              color={decoColor.text}
            >
              {type} WINE RECOMMENDATION
            </Typography>
            <Box
              component="img"
              src={image}
              sx={{
                aspectRatio: "1.3",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{ lineHeight: 1.2, whiteSpace: "pre-line" }}
            >
              {name}
            </Typography>
            <Typography
              variant="caption"
              color="text.disabled"
              sx={{ whiteSpace: "pre-line", lineHeight: 1.2 }}
            >
              {nameEnglish}
            </Typography>
          </Box>
          <Divider sx={{ margin: "0 1rem" }} />
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: "500" }}>
              • 수입사 | {provider}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                lineHeight: 1.4,
                whiteSpace: "pre-line",
                fontWeight: "500",
              }}
            >
              {description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.2rem",
                flexWrap: "wrap",
              }}
            >
              {tags.map((tag) => (
                <Box
                  key={tag}
                  sx={(theme) => ({
                    backgroundColor: opacify(-0.95, theme.palette.primary.main),
                    color: theme.palette.primary.main,
                    padding: "0.2em 0.75em",
                    border: "1px solid",
                    fontSize: "0.75rem",
                    fontWeight: "600",
                    borderRadius: "1em",
                    borderColor: opacify(-0.95, theme.palette.primary.main),
                  })}
                >
                  #{tag}
                </Box>
              ))}
            </Box>
          </Box>
          {id != null && (
            <Button
              fullWidth
              variant="contained"
              size="large"
              sx={{ borderRadius: 0, fontWeight: "bold" }}
              component="a"
              href={`https://www.marketbang.kr/detail/${id}`}
              target="_blank"
            >
              와인 자세히 보러가기 {">"}
            </Button>
          )}
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default WineTile;
