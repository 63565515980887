import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export type AlertDialogProps = {
  open: boolean;
  onClose?: () => void;
  message?: string;
};

const AlertDialog: React.FC<AlertDialogProps> = ({
  message,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ minWidth: "25rem" }}>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>확인</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
