import { Box, Button, Typography } from "@mui/material";
import { ReactNode } from "react";

export type PaperIconButtonProps = {
  icon: ReactNode;
  children: ReactNode;
  onClick: () => void;
};

const PaperIconButton: React.FC<PaperIconButtonProps> = ({
  icon,
  children,
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      fullWidth
      sx={{
        borderColor: "#e6e6e6",
        display: "inline-flex",
        gap: "0.75rem",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: "1.75rem",
          height: "1.75rem",
          borderRadius: "100%",
          backgroundColor: "#D73B8D",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
      <Typography fontWeight="600" color="black">
        {children}
      </Typography>
    </Button>
  );
};

export default PaperIconButton;
