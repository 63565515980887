import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useState } from "react";
import { IoWine } from "react-icons/io5";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import Chat from "../components/Chat";
import Footer from "../components/Footer";
import PaperIconButton from "../components/PaperIconButton";
import WineTile from "../components/WineTile";
import { Answer } from "../features/answer";
import {
  createResultMessages,
  createResultPriceText,
} from "../features/result";
import { Wine } from "../features/wine";
import { delay } from "../helpers/functions";
import { ReactComponent as RefreshIcon } from "../images/refresh.svg";
import { ReactComponent as ShareIcon } from "../images/share.svg";

const ResultPage: React.FC = () => {
  const navigate = useNavigate();
  const { answer, wines } = useLoaderData() as {
    answer: Answer;
    wines: Wine[];
  };

  const [showPriceMessage, setShowPriceMessage] = useState(false);
  const [showWines, setShowWines] = useState(false);

  const messages = createResultMessages(answer);
  const priceMessage = createResultPriceText(answer);

  const handleCopy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("링크가 복사되었습니다.");
  };

  const handleRedo = () => {
    navigate("/");
  };

  if (answer.storeName == null) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      <Box
        sx={{
          minHeight: "var(--screen-height)",
          display: "flex",
          flexDirection: "column",
          padding: "7vh 1rem",
          maxWidth: "30rem",
          margin: "0 auto",
        }}
      >
        <Chat
          messages={messages}
          onCompleted={async () => {
            await delay(1000);
            setShowPriceMessage(true);
          }}
        />
        {showPriceMessage && (
          <Typography
            component={motion.div}
            variants={{
              hide: { opacity: 0 },
              show: { opacity: 1 },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            variant="h5"
            marginTop="4vh"
            whiteSpace="pre-line"
            onAnimationComplete={async () => {
              await delay(1000);
              setShowWines(true);
            }}
          >
            {priceMessage}
          </Typography>
        )}
        {showWines && (
          <Box
            component={motion.div}
            variants={{
              hide: {},
              show: { opacity: 1, transition: { staggerChildren: 0.25 } },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            sx={{
              marginTop: "7vh",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {wines.length < 1 ? (
              <Box sx={{ padding: "2rem 1rem", textAlign: "center" }}>
                <Typography color="text.disabled">
                  추천드릴 와인이 없어요.
                </Typography>
              </Box>
            ) : (
              wines.map((wine, index) => (
                <WineTile
                  key={index}
                  id={wine.id}
                  type={wine.type}
                  name={wine.name}
                  nameEnglish={wine.nameEnglish}
                  tags={wine.tags}
                  provider={wine.provider}
                  image={wine.image}
                  description={wine.description}
                />
              ))
            )}
            <Box
              sx={{
                marginTop: "1.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
              }}
            >
              <PaperIconButton
                icon={<ShareIcon width="1rem" height="1rem" />}
                onClick={handleCopy}
              >
                와인 추천 주변에 공유하기
              </PaperIconButton>
              <PaperIconButton
                icon={<RefreshIcon width="1rem" height="1rem" />}
                onClick={handleRedo}
              >
                테스트 다시하기
              </PaperIconButton>
              <PaperIconButton
                icon={<IoWine size="1.2rem" color="white" />}
                onClick={() => {
                  window.open(
                    "https://threelabs.notion.site/5b3a70df8b014d758d3666c371c35d2d"
                  );
                }}
              >
                전문가의 더 상세한 와인 추천 받기
              </PaperIconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default ResultPage;
