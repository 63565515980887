import Box from "@mui/material/Box";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { delay } from "../helpers/functions";
import Message from "./Message";

export type ChatMessage = {
  message: string;
  mine?: boolean;
};

export type ChatProps = {
  messages: ChatMessage[];
  onCompleted?: () => void;
};

const Chat: React.FC<ChatProps> = ({ messages, onCompleted }) => {
  const [showingMessages, setShowingMessages] = useState<ChatMessage[]>([]);
  const animate = async () => {
    setShowingMessages([]);
    for (let i = 0; i < messages.length; i++) {
      const current = messages[i];
      const previous = messages[i - 1];
      await delay(current?.mine === previous?.mine ? 500 : 1000);
      setShowingMessages((previous) => [...previous, messages[i]]);
    }
    onCompleted?.();
  };
  useEffect(() => {
    animate();
    // eslint-disable-next-line
  }, []);
  return (
    <Box
      component={motion.div}
      initial="hide"
      animate="show"
      exit="hide"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "0.75rem",
      }}
    >
      {showingMessages.map((message, index) => (
        <Message key={index} mine={message.mine}>
          {message.message}
        </Message>
      ))}
    </Box>
  );
};

export default Chat;
