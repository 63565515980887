import { Answer } from "./answer";
import { Message } from "./message";
import {
  FoodType,
  MeatType,
  MusicType,
  PriceType,
  StoreType,
} from "./question";

const meatMents: Record<MeatType, string> = {
  [MeatType.CHARCUTERIE]: "샤퀴테리와 페어링하기 좋은",
  [MeatType.PORK]: "돼지고기와 페어링하기 좋은",
  [MeatType.BEEF]: "소고기와 페어링하기 좋은",
  [MeatType.LAMB]: "양고기와 페어링하기 좋은",
  [MeatType.BIRD]: "조류요리와 페어링하기 좋은",
};

const foodMents: Record<FoodType, string> = {
  [FoodType.KOREAN]: "한식 요리와 페어링하기 좋은",
  [FoodType.JAPANESE]: "일식 요리와 페어링하기 좋은",
  [FoodType.CHINESE]: "중식 요리와 페어링하기 좋은",
  [FoodType.FRENCH]: "프렌치 요리와 페어링하기 좋은",
  [FoodType.ITALIAN]: "이탈리아 요리와 페어링하기 좋은",
  [FoodType.SPANISH]: "스페니쉬 요리와 페어링하기 좋은",
  [FoodType.VEGAN]: "비건 요리와 페어링하기 좋은",
  [FoodType.NORTHERN_EUROPE]: "북유럽 요리와 페어링하기 좋은",
  [FoodType.ASIAN]: "아시아 요리와 페어링하기 좋은",
  [FoodType.FUSION]: "퓨전 요리와 페어링하기 좋은",
  [FoodType.ETC_WESTERN]: "양식 요리와 페어링하기 좋은",
};

const musicMents: Record<MusicType, string> = {
  [MusicType.CLASSIC]: "풍부하고 복잡한, 집중도 높은",
  [MusicType.JAZZ]: "다채롭고 산뜻하며, 여운이 긴",
  [MusicType.POP]: "유니크하고 생동감 넘치는",
  [MusicType.ACOUSTIC]: "친근하고 섬세한, 실키한",
  [MusicType.KPOP]: "트렌디하고 컬러풀한, 과일향이 풍부한",
};

const priceMents: Record<PriceType, string> = {
  [PriceType.LOW]: "1-2만원대 가성비 좋은",
  [PriceType.MEDIUM]: "3-5만원대 합리적인 가격의",
  [PriceType.HIGH]: "6-9만원대 고퀄리티",
  [PriceType.PREMIUM]: "10만원 이상의 프리미엄",
  [PriceType.ALL]: "다양한 가격대의",
};

export function createResultMessages(answer: Answer) {
  const foodText =
    answer.storeType === StoreType.MEAT_RESTAURANT && answer.meat != null
      ? meatMents[answer.meat]
      : [StoreType.FINE_DINING, StoreType.RESTAURANT].includes(
          answer.storeType
        ) && answer.food != null
      ? foodMents[answer.food]
      : "매장과 어울리는";

  return [
    { message: `${answer.storeName} 님의` },
    { message: foodText },
    { message: `${musicMents[answer.music!]} 와인을 추천드려요!` },
  ].filter((x): x is Message => !!x);
}

export function createResultPriceText(answer: Answer) {
  return [
    `${answer.storeName} 님의 매장에 잘 어울리는`,
    `${priceMents[answer.price!]} 와인이에요!`,
  ].join("\n");
}
